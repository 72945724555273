<!--防洪体系-->
<template>
  <div class="box-card">
    <div class="search-form" style="text-align: left">
      <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="70px" label-position="left"
        class="demo-form-inline" size="mini">
          <el-form-item label="国家/州/省" label-width="80px">
            <el-cascader
              v-model='value1'
              :options="countryList"
              :props="{ checkStrictly: true }"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="收件地址">
            <el-input clearable size="mini" v-model="searchForm.address" placeholder="请输入收件地址"></el-input>
          </el-form-item>
          <el-form-item label="收件电话">
            <el-input clearable size="mini" v-model="searchForm.receive_mobile" placeholder="请输入收件电话"></el-input>
          </el-form-item>
          <el-form-item label="电商订单号" label-width="82px">
            <el-input clearable size="mini" v-model="searchForm.hb_order_sn" placeholder="请输入电商订单号"></el-input>
          </el-form-item>
          <el-form-item label="物流单号/订单编号" label-width="130px">
            <el-input clearable size="mini" v-model="searchForm.porder_s" placeholder="批量搜索物流单号/订单编号" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="创建时间" prop="materielNameResult">
            <el-date-picker
              style="width:320px;"
              v-model="searchForm.createtime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="mini" @click="handleSearch">查询</el-button>
            <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="resetSearch">重置</el-button>
          </el-form-item>
      </el-form>
    </div>
    <div class="" style="margin-bottom: 15px;text-align:left">
      <el-button type="primary" size="mini" v-if='activeName == 4' @click="handleBatchOrder">批量生成打单</el-button>
      <el-button type="success" size="mini" v-if='activeName == 4' @click="handleBatchOrderRate">批量预估运费</el-button>
      <el-button type="danger" size="mini" v-if='activeName == 4' @click="handleDel">批量删除</el-button>
      <el-button type="danger" size="mini" v-if='activeName == 1' @click="handleCancel">批量取消订单</el-button>
      <el-button type="primary" size="mini" @click="handleExport">导出数据</el-button>
      <el-button type="warning" size="mini" v-if='activeName == 4' @click="handleImportEx">导入订单</el-button>
    </div>
    <el-table :data="dataList" ref="multipleTable" border size="mini" height="500" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
      <el-table-column prop="order_sn" label="订单编号" show-overflow-tooltip width="180">
        <template slot-scope="scope">
          <div style="color: #409EFF;cursor: pointer;" @click="handleEdit(scope.row)">
            {{ scope.row.order_sn }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="hb_order_sn" label="电商订单号" width="140" show-overflow-tooltip></el-table-column>
      <el-table-column align='center' label="物流信息">
        <el-table-column prop="platform_order_sn" label="物流单号" show-overflow-tooltip width="170">
          <template slot-scope="scope">
            <div>
              {{ scope.row.platform_order_sn }} <i class="el-icon-document-copy copy-icon-btn" v-if="scope.row.platform_order_sn" @click="copy(scope.row.platform_order_sn)"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="service_type" label="物流服务商" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="count_package" label="件数" show-overflow-tooltip></el-table-column>
        <el-table-column prop="pa_lb" label="重量" show-overflow-tooltip></el-table-column>
      </el-table-column>
      <el-table-column align='center' label="费用">
        <el-table-column prop="rate_money" label="扣款金额" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fuel_money" label="燃油费" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address_money" label="偏远费" show-overflow-tooltip></el-table-column>
        <el-table-column prop="itemized_price" label="住宅费" show-overflow-tooltip></el-table-column>
        <el-table-column prop="insurance" label="保险金额" show-overflow-tooltip></el-table-column>
      </el-table-column>
      <el-table-column align='center' label="收件人">
        <el-table-column prop="receive_country" label="国家" show-overflow-tooltip width="120"></el-table-column>
        <el-table-column prop="receive_province" label="州/省" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="地址" show-overflow-tooltip width="160"></el-table-column>
        <el-table-column prop="receive_realname" label="姓名" show-overflow-tooltip width="120"></el-table-column>
        <el-table-column prop="receive_mobile" label="电话" show-overflow-tooltip width="100"></el-table-column>
        <el-table-column prop="receive_zcode" label="邮编" show-overflow-tooltip></el-table-column>
      </el-table-column>
      <el-table-column prop="createtime" label="创建日期" show-overflow-tooltip width="120"></el-table-column>
      <el-table-column prop="check_status" label="验证状态" show-overflow-tooltip></el-table-column>
        <el-table-column prop="order_package_num" label="是否多包裹" width="90" show-overflow-tooltip></el-table-column>
        <el-table-column prop="is_sign" label="是否签名" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.is_sign == 0 ? '否' : '是' }}
          </template>
        </el-table-column>
        <el-table-column prop="is_international" label="是否国际单" width="90" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.is_international == 0 ? '否' : '是' }}
          </template>
        </el-table-column>
      <!-- <el-table-column prop="od_num27" label="复制物流单号" show-overflow-tooltip></el-table-column> -->
      <el-table-column prop="action" label="操作" width="260" fixed="right">
        <template slot-scope="scope">
          <div>
            <span style="color: #67C23A; cursor: pointer" v-if='activeName != 4 && activeName != 5' @click="handleEdit(scope.row)">复制为新订单</span>
            <el-divider direction="vertical" v-if='activeName != 4 && activeName != 5'></el-divider>
            <span style="color: #E6A23C; cursor: pointer" v-if="scope.row.file_path" @click='handlePdf(scope.row.file_path)'>PDF文件</span>
            <el-divider direction="vertical" v-if="scope.row.file_path"></el-divider>
            <span style="color: #409eff; cursor: pointer" v-if="scope.row.platform_order_sn" @click='handleBar(scope.row.platform_order_sn)'>条形码</span>
            <el-divider direction="vertical" v-if="scope.row.platform_order_sn"></el-divider>
            <span style="color: #F56C6C; cursor: pointer" @click='handleParcel(scope.row.order_id)'>包裹信息</span>
            <el-divider direction="vertical" v-if="activeName == 1 && scope.row.file_path== '' && scope.row.service_type == 'US022'"></el-divider>
            <span style="color: #F56C6C; cursor: pointer" v-if="activeName == 1 && scope.row.file_path== '' && scope.row.service_type == 'US022'" @click='handleSheet(scope.row.order_id)'>获取面单</span>
            <!-- <el-divider direction="vertical" v-if='activeName == 2'></el-divider>
            <span style="color: #409eff; cursor: pointer" v-if='activeName == 2' @click='handleSheet'>申请退款</span> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagization-box">
      <CustomPagization :background="true" :currentPage="current" :total="total" :pageSize="size"
        @current-change="currentChange" />
    </div>
    <!-- PDF文件 -->
    <el-dialog
      title="查看PDF文件"
      append-to-body
      :visible.sync="pdfVisible"
      width="900px"
      :before-close="closePdfDialog"
    >
      <PDFs
        v-if="pdfVisible"
        :filePath='filePath'
        append-to-body
      />
    </el-dialog>
    <!-- 条形码 -->
    <el-dialog
      title="查看条形码"
      append-to-body
      :visible.sync="barVisible"

      :before-close="closeBarDialog"
    >
      <Barcode
        v-if="barVisible"
        :platform_order_sn='platform_order_sn'
        append-to-body
      />
    </el-dialog>
    <!-- 包裹信息 -->
    <el-dialog
      title="查看包裹信息"
      append-to-body
      :visible.sync="parcelVisible"
      width="600px"
      :before-close="closeParcelDialog"
    >
      <Parcel
        v-if="parcelVisible"
        append-to-body
        :order_id='order_id'
      />
    </el-dialog>
    <!-- 面单 -->
    <el-dialog
      :title="sheetTitle"
      append-to-body
      :visible.sync="sheetVisible"
      width="500px"
      :before-close="closeSheetDialog"
    >
      <Sheet
        v-if="sheetVisible"
        append-to-body
        :sheetList='sheetList'
      />
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑"
      append-to-body
      :visible.sync="splaceVisible"
      width="1280px"
      :before-close="closeSplaceDialog"
    >
      <Place
        v-if="splaceVisible"
        :record='record'
        @orderSuccess='orderSuccess'
        append-to-body
      />
    </el-dialog>
    <!-- 导入 -->
    <el-dialog
      title="导入"
      append-to-body
      :visible.sync="importVisible"
      width="500px"
      :before-close="closeImportDialog"
    >
      <ImportEx
        v-if="importVisible"
        :record='record'
        @uploadSuccess='uploadSuccess'
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
  import { orderList, orderExport, cancelOrder, orderDel, batchOrder, batchOrderRate, labelinfo } from '@/api/order'
  import Barcode from './barcode.vue'
  import Parcel from './parcel.vue'
  import PDFs from './PDFs.vue'
  import Sheet from './sheet.vue'
  import Place from '../place.vue'
  import ImportEx from './importEx.vue'
  import { transformCountryCity, downloadBase64AsExcel } from "@/common/common.js"
  import { City } from '@/api/common'
  export default {
    components: {
      Barcode,
      Parcel,
      PDFs,
      Sheet,
      Place,
      ImportEx
    },
    props: {
      activeName: {
        type: [Number,String],
        default: 0
      }
    },
    data() {
      return {
        sheetTitle: '预估运费',
        platform_order_sn: '',
        pdfVisible: false,
        barVisible: false,
        parcelVisible: false,
        sheetVisible: false,
        splaceVisible: false,
        importVisible: false,
        filePath: '', // pdf 链接
        sheetList: [],// 批量预估和批量打单数据
        order_id: '',
        record: {},
        searchForm: {},
        value1:[],
        current: 1,
        size: 20,
        total: 1,
        dataList: [],
        multipleSelection: [],
        countryList: [],// 国家-城市
      };
    },
    computed: {
      ids() {
        let ids = [];
        this.multipleSelection.forEach((ele) => {
          ids.push(ele.order_id);
        });
        return ids.join(",");
      },
    },
    created() {
      this.getOrderList();
      this.getCity()
    },
    methods: {
      // 获取城市
      getCity(){
        City().then(res => {
          this.countryList = transformCountryCity(res.data)
        })
      },
      //获取省市县
      handleChange(value) {
        this.searchForm.country = value[0]
        if(value[1]){
          this.searchForm.province = value[1]
        }
      },
      //查看PDF文件
      handlePdf(file){
        this.filePath = file
        window.open(file, '_blank')
        // this.pdfVisible = true
      },
      // PDF文件弹窗
      closePdfDialog(){
        this.filePath = ''
        this.pdfVisible = false
      },
      // 查看条形码
      handleBar(platform_order_sn){
        this.platform_order_sn = platform_order_sn
        this.barVisible = true
      },
      // 关闭条形码弹窗
      closeBarDialog(){
        this.platform_order_sn = ''
        this.barVisible = false
      },
      // 查看包裹信息
      handleParcel(id){
        this.order_id = id
        this.parcelVisible = true
      },
      // 关闭包裹信息弹窗
      closeParcelDialog(){
        this.order_id = ''
        this.parcelVisible = false
      },
      // 导入
      handleImportEx(){
        this.importVisible = true
      },
      // 关闭导入
      closeImportDialog(){
        this.importVisible = false
      },
      // 查看面单
      handleSheet(id){
        labelinfo({
          user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
          order_id: id
        }).then(res => {
          if(res.code == 1){
            this.filePath = res.data.file_path
            // this.pdfVisible = true
            window.open(res.data.file_path, '_blank')
          }else{
            this.$message.error(res.msg);
          }
        }).catch(err => {
          this.$message.error('获取面单失败！');
        })
      },
      // 关闭面单弹窗
      closeSheetDialog(){
        this.sheetList = []
        this.sheetVisible = false
      },
      // 编辑
      handleEdit(row){
        this.record = row
        this.splaceVisible = true
      },
      // 关闭编辑
      closeSplaceDialog(){
        this.record = {}
        this.splaceVisible = false
      },
      // 成功后回调
      orderSuccess(){
        this.splaceVisible = false
        this.current = 1
        this.getOrderList()
      },
      // 复制订单号
      copy(num){
        let oInput = document.createElement('input');
        oInput.value = num;
        document.body.appendChild(oInput);
        oInput.select();
        document.execCommand('Copy');
        this.$message.success('复制成功');
        oInput.remove();
      },
      handleAdd(){},
      // 批量删除
      handleDel(){
        this.$confirm("确认删除选中订单?", "温馨提示", {
          type: "warning",
        })
        .then(() => {
          orderDel({ 
            user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
            ids: this.ids
          }).then(res => {
            if(res.code == 1){
              this.current = 1
              this.$message.success(res.msg);
              this.getOrderList();
            }else{
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
      },
      // 批量取消
      handleCancel(){
        this.$confirm("确认取消选中订单?", "温馨提示", {
          type: "warning",
        })
        .then(() => {
          cancelOrder({ 
            user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
            ids: this.ids
          }).then(res => {
            if(res.code == 1){
              this.current = 1
              this.$message.success(res.msg);
              this.getOrderList();
            }else{
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
      },
      // 批量生成打单
      handleBatchOrder(){
        this.$confirm("确认将选中打单?", "温馨提示", {
          type: "warning",
        })
        .then(() => {
          batchOrder({ 
            user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
            ids: this.ids
          }).then(res => {
            if(res.data.error_msg.length > 0){
              this.sheetList = res.data.error_msg
              this.sheetTitle = '打单'
              this.sheetVisible = true
            }else{
              this.current = 1
              this.$message.success(res.msg);
              this.getOrderList();
            }
          });
        })
        .catch((err) => {
          if(err.data.error_msg.length > 0){
            this.sheetList = err.data.error_msg
            this.sheetTitle = '打单'
            this.sheetVisible = true
          }else{
            this.current = 1
            this.$message.success(err.msg);
            this.getOrderList();
          }
        });
      },
      // 批量预估运费
      handleBatchOrderRate(){
        this.$confirm("确认预估选中订单运费?", "温馨提示", {
          type: "warning",
        })
        .then(() => {
          batchOrderRate({ 
            user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
            ids: this.ids
          }).then(res => {
            if(res.data.error_msg.length > 0){
              this.sheetList = res.data.error_msg
              this.sheetTitle = '预估运费'
              this.sheetVisible = true
            }else{
              this.current = 1
              this.$message.success(res.msg);
              this.getOrderList();
            }
          });
        })
        .catch((err) => {
          if(err.data.error_msg.length > 0){
            this.sheetList = err.data.error_msg
            this.sheetTitle = '预估运费'
            this.sheetVisible = true
          }else{
            this.current = 1
            this.$message.success(err.msg);
            this.getOrderList();
          }
        });
      },
      handleSelectionChange(e){
        this.multipleSelection = e
      },
      // 导出数据
      handleExport(){
        let queryForm = {}
        if(this.searchForm.createtime){
          queryForm = {
            ...this.searchForm,
            createtime: this.searchForm.createtime.join("/")
          }
        }else{
          queryForm = {
            ...this.searchForm
          }
        }
        orderExport({
          user_code:JSON.parse(window.localStorage.getItem("userInfo")).user_code,
          order_status: this.activeName,
          ...queryForm
        }).then(res => {
          downloadBase64AsExcel(res.data.file,res.data.name)
        })
      },
      // 多选
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      // 关闭弹框
      closeDialog() {
        this.record = {};
        this.formVisible = false;
      },
      //新增、修改成功的回调
      editSuccess() {
        this.formVisible = false;
        this.getOrderList();  //刷新列表
      },
      uploadSuccess(){
        this.importVisible = false
        this.current = 1
        this.getOrderList()
      },
      // 获取列表
      getOrderList(){
        let queryForm = {}
        if(this.searchForm.createtime){
          queryForm = {
            ...this.searchForm,
            createtime: this.searchForm.createtime.join("/")
          }
        }else{
          queryForm = {
            ...this.searchForm
          }
        }
        orderList({
          user_code:JSON.parse(window.localStorage.getItem("userInfo")).user_code,
          page: this.current,
          limit: this.size,
          order_status: this.activeName,
          ...queryForm
        }).then(res => {
          if(res.code == 1){
            this.dataList = res.data.list
            this.total = res.data.total
          }
        })
      },
      // table首列序号
      indexMethod(index) {
        return (this.current - 1) * this.size + index + 1;
      },
      // 页数变化
      currentChange(current) {
        this.current = current;
        this.getOrderList();
      },
      // 重置筛选
      resetSearch() {
        this.$refs["searchForm"].resetFields();
        this.searchForm = {}
        this.value1 = []
        this.current = 1;
        this.total = 1;
        this.dataList = [];
        this.handleSearch();
      },
      // 去筛选
      handleSearch() {
        this.current = 1;
        this.total = 1;
        this.dataList = [];
        this.getOrderList();
      },
      // 跳往轨迹
      search(num){
        this.$router.push({ path: '/trace', query: { num:num } });
      },
    },
  };
</script>

<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
/deep/.el-dialog__body{
  padding: 0 20px !important;
}
.copy-icon-btn{
  font-size: 16px;
  margin-left: 10px;
  color:#409EFF;
  cursor: pointer;
}
</style>
