<template>
  <div class="x-tops" :style="{ width: isCollapse ? '60px' : '230px' }">
    <div class="x-title">
      <span v-if="isCollapse">C</span>
      <span v-else>
        <img :src="require('@/assets/logo.png')" alt="Carousel Image" class="logo-img" @click='goToIndex'>
      </span>
    </div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      unique-opened
      background-color="#409EFF"
      text-color="#fff"
      active-text-color="#fff"
      router
    >
      <div v-for="(item, index) in nav" :key="index">
        <el-submenu :index="item.url" v-if="item.ischildren">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title" v-if="!isCollapse">{{ item.name }}</span>
          </template>
          <el-menu-item
            :index="item1.url"
            v-for="(item1, index1) in item.children"
            :key="index1"
            >{{ item1.name }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item :index="item.url" v-else>
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      nav: [
        {
          uid: 1,
          icon: "el-icon-document-add",
          name: "国内订单",
          url: "/order/place",
          ischildren: false,
        },
        // {
        //   uid: 2,
        //   icon: "el-icon-document-checked",
        //   name: "国际订单",
        //   url: "/order/fplace",
        //   ischildren: false,
        // },
        {
          uid: 3,
          icon: "el-icon-tickets",
          name: "订单管理",
          url: "/order/list",
          ischildren: false,
        },
        {
          uid: 4,
          icon: "el-icon-location-information",
          name: "地址管理",
          url: "/address",
          ischildren: false,
        },  
        {
          uid: 5,
          icon: "el-icon-notebook-1",
          name: "财务管理",
          url: "5",
          ischildren: true,
          children: [
            {
              uid: 6,
              icon: "el-icon-location",
              name: "消费流水",
              url: "/finance/consumption",
            },
            {
              uid: 7,
              icon: "el-icon-location",
              name: "信用流水",
              url: "/finance/credit",
            },
            {
              uid: 8,
              icon: "el-icon-location",
              name: "发票列表",
              url: "/finance/invoice",
            },
          ],
        },
        {
          uid: 9,
          icon: "el-icon-school",
          name: "服务商列表",
          url: "/facilitator/list",
          ischildren: false,
        },
        {
          uid: 10,
          icon: "el-icon-setting",
          name: "账号管理",
          url: "10",
          ischildren: true,
          children: [
            // {
            //   uid: 9,
            //   icon: "el-icon-location",
            //   name: "会员中心",
            //   url: "/member",
            // },
            {
              uid: 10,
              icon: "el-icon-location",
              name: "个人信息",
              url: "/info",
            },
            {
              uid: 11,
              icon: "el-icon-location",
              name: "修改密码",
              url: "/password",
            }
          ],
        },
      ],
    };
  },
  methods: {
    // 接收顶部切换折叠
    receiveData(e) {
      this.isCollapse = e;
    },
    goToIndex(){
      this.$router.push({ path: '/'});
    }
  },
};
</script>
<style lang='scss' scoped>
.x-tops {
  height: 100vh;
  background: #409EFF;
  overflow: hidden;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 230px;
  text-align: left;
}
/deep/.el-menu-item.is-active {
  border-left: 4px solid #E6A23C;
  background: rgb(7, 123, 243) !important;
}
/deep/.el-menu-item i,/deep/.el-submenu__title i{
  color: #fff;
  opacity: 0.8;
}
/deep/.el-menu{
  border: none;
}
.logo-img{
  width: 100px;
  height: 40px;
  margin: 8px auto;
  cursor: pointer;
}
.x-title {
  height: 50px;
  width: 100%;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  border: 1px solid #409EFF;
  span {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI,
      PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica,
      Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  }
}
</style>