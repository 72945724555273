<template>
  <div class="">
    <el-card class="box-card">
      <!-- <div class="search-form" style="text-align: left">
        <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="100px" label-position="left"
          class="demo-form-inline" size="mini">
          <el-form-item label="	地址类型">
            <el-select v-model="searchForm.type" placeholder="请选择地址类型">
              <el-option label="发件人地址" :value="1"></el-option>
              <el-option label="收件人地址" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="mini" @click="handleSearch">查询</el-button>
            <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div> -->
      <div class="" style="margin-bottom: 15px;text-align:left">
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增地址</el-button>
      </div>
      <el-table :data="dataList" border size="mini" height="600">
        <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
        <!-- <el-table-column prop="realname" label="昵称" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="realname" label="姓名" show-overflow-tooltip></el-table-column>
        <el-table-column prop="country" label="国家" show-overflow-tooltip></el-table-column>
        <el-table-column prop="province" label="州/省" show-overflow-tooltip></el-table-column>
        <el-table-column prop="city" label="城市" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address_one" label="地址1/2" show-overflow-tooltip width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.address_one }}</p>
            <p>{{ scope.row.address_two }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="zcode" label="邮编" show-overflow-tooltip></el-table-column>
        <el-table-column prop="email" label="邮箱" show-overflow-tooltip></el-table-column>
        <el-table-column prop="user_type" label="类型" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.user_type == 1 ? '发件人地址' : '收件人地址' }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="remark" label="备注" show-overflow-tooltip></el-table-column> -->
        <!-- <el-table-column prop="is_default" label="默认地址" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_default" active-color="#13ce66" :inactive-value='0' :active-value='1' @change='(checked) => handleChangeDefault(checked, scope.row.id)'></el-switch>
          </template>
        </el-table-column> -->
        <el-table-column prop="action" label="操作" width="140">
          <template slot-scope="scope">
            <div>
              <span style="color: #409eff; cursor: pointer" @click="handleEdit(scope.row)">修改</span>
              <el-divider direction="vertical"></el-divider>
              <span style="color: #f40000; cursor: pointer" @click="handleDel(scope.row)">删除</span>
              <el-divider direction="vertical" v-if="isType"></el-divider>
              <span style="color: #B2AE10; cursor: pointer" @click="handleSelect(scope.row)" v-if="isType">选择</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>

    <!-- 表单弹窗 -->
    <el-dialog
      :title="title"
      append-to-body
      :visible.sync="formVisible"
      width="900px"
      :before-close="closeDialog"
    >
      <Form
        ref="formModal"
        :record="record"
        v-if="formVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import Form from "./form";
import { address, addressDel } from '@/api/address'
export default {
  props: {
    isType: {
      type: [Number, String],
      default: ''
    }
  },
  components: {
    Form,
  },
  data() {
    return {
      formVisible: false,
      record: {},
      title: '新增地址',
      searchForm: {},
      current: 1,
      size: 20,
      total: 1,
      dataList: [],
    };
  },
  mounted(){
    if(this.isType){
      this.searchForm.address_type = this.isType
    }
    this.getAddress()
  },
  methods: {
    // 开关设置默认地址
    // handleChangeDefault(e,id){
    //   defaultUpdate({
    //     user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
    //     address_id: id
    //   }).then(res => {
    //     if(res.code == 1){
    //       this.$message.success(res.msg);
    //       this.getAddress();
    //     }else{
    //       this.$message.success(res.msg);
    //     }
    //   })
    // },
    // 获取列表
    getAddress(){
      address({
        user_code:JSON.parse(window.localStorage.getItem("userInfo")).user_code,
        page: this.current,
        limit: this.size,
        ...this.searchForm
      }).then(res => {
        if(res.code == 1){
          this.dataList = []
          this.dataList = res.data.list
          this.total = res.data.total
        }
      })
    },
    // 关闭弹框
    closeDialog() {
      this.record = {};
      this.formVisible = false;
    },
    //新增、修改成功的回调
    editSuccess() {
      this.formVisible = false;
      this.getAddress();  //刷新列表
    },
    //视频监控站点新增
    handleAdd() {
      this.title = "新增地址"
      this.record = {}
      this.formVisible = true
    },
    //视频监控站点修改
    handleEdit(record) {
      this.title = "编辑地址"
      this.record = {
        ...record
      }
      this.formVisible = true
    },
    //视频监控站点删除
    handleDel(record) {
      const _that = this;
      this.$confirm("确认删除?", "温馨提示", {
        type: "warning",
      })
        .then(() => {
          addressDel({ 
            user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
            id: record.id 
          }).then(res => {
            if(res.code == 1){
              this.current = 1
              this.$message.success(res.msg);
              this.getAddress();
            }else{
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    //新增、修改成功的回调
    editSuccess() {
      this.formVisible = false
      this.getAddress();
    },
    // 选择地址
    handleSelect(scope){
      this.$emit('ok',scope,this.isType)
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getAddress();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.value1 = []
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.getAddress();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>
