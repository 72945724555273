<template>
  <div class="login-container">
    <div class="background-image"></div>
    <div class="login-form">
      <el-image
        style="width: 100px; height: 40px;margin: 35px auto 15px;"
        :src="require('@/assets/logo.png')"
        fit="contain"
      ></el-image>
      <!-- <p class="login-tip">注册账号</p> -->
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="80px"
      >
        <el-form-item label='账号' prop="username">
          <el-input v-model="ruleForm.username" clearable placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label='密码' prop="password">
          <el-input v-model="ruleForm.password" type='password' autocomplete="off" clearable placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label='确认密码' prop="password1">
          <el-input v-model="ruleForm.password1" type='password' autocomplete="off" clearable placeholder="请输入确认密码"></el-input>
        </el-form-item>
        <div class="no-pass">
          <span class="no-pass-btn" @click="goLogin">登录</span>
        </div> 
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')" style="width: 100%">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="x-tips">CTS GLOBAL SUPPLY CHAIN SOLUTIONS</div>
  </div>
</template>

<script>
import { usersRegister } from '@/api/login'
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.ruleForm.password1 !== '') {
          this.$refs.ruleForm.validateField('password1');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      formVisible: false,
      ruleForm: {
        username: "",
        password: "",
        password1: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        password1: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    goLogin(){
      this.$router.push({ path: '/login'})
    },
    // 关闭
    closeDialog(){
      this.formVisible = false
    },
    // 登录/注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          usersRegister(this.ruleForm).then(res => {
            if(res.code == 1){
              window.localStorage.setItem("userInfo",JSON.stringify(res.data.userinfo))
              this.$message.success(res.msg)
              this.$router.push({ path: '/order/place'});
            }else{
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    }
  },
};
</script>

<style lang='scss' scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/bg.jpg");
  background-size: cover;
  /* opacity: 0.5; */
}

.login-form {
  width: 450px;
  height: 400px;
  z-index: 99;
  box-sizing: border-box;
  padding: 0 40px;
  background:#fff !important;
  border-radius: 8px;
  .code{
    font-size: 14px;
    cursor: pointer;
    color: #409EFF;
    margin-left: 10px;
    flex: 1;
    text-align: right;
    width: 100px;
    float: right;
  }
  .login-tip{
    width: 100%;
    font-size: 14px;
    padding: 0;
    margin: 0;
    height: 30px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
  }
  .demo-ruleForm{
    width: 350px;
    height: 300px;
  }
}

/deep/.el-input--prefix .el-input__inner{
  padding-left: 40px !important;
}
.no-pass{
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
  .no-pass-btn{
    line-height: 28px;
    font-size: 14px;
    cursor: pointer;
    color: #409EFF;
  }
}
.x-tips{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  text-align: center;
  font-size: 16px;
  color: #fff;
}
</style>
