<template>
  <div class="">
    <el-table :data="freeInfo.money_list" border size="mini" :span-method="spanMethod">
      <el-table-column label="选择" width="55">
        <template slot-scope="scope">
          <el-radio
            v-model="currentRow"
            :label="scope.row"
            class="hidden-label-radio"
            v-if="scope.row.msg != '该渠道服务不支持'"
          ></el-radio>
        </template>
      </el-table-column>
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="service_name" label="物流产品">
        <template slot-scope="scope">
          {{ scope.row.msg != '该渠道服务不支持' ? scope.row.service_name : '该渠道服务不支持' }}
        </template>
      </el-table-column>
      <el-table-column prop="all_money" label="总运费" show-overflow-tooltip width="80">
        <template slot-scope="scope">
          {{ scope.row.all_money.toFixed(2)}}
        </template>
      </el-table-column>
      <el-table-column prop="fuel_money" label="燃油" show-overflow-tooltip width="60"></el-table-column>
      <el-table-column prop="weight" label="重量" show-overflow-tooltip width="70"></el-table-column>
      <el-table-column prop="money2" label="住宅" show-overflow-tooltip width="70"></el-table-column>
      <el-table-column prop="money3" label="AHS" show-overflow-tooltip width="70"></el-table-column>
      <el-table-column prop="money4" label="OS" show-overflow-tooltip width="70"></el-table-column>
      <el-table-column prop="money5" label="其他" show-overflow-tooltip></el-table-column>
    </el-table>
    <div class="free-bot">
      <div class="free-title">价格最优：<span style="color: #F56C6C;">{{ freeInfo.first_service }}</span></div>
      <div class="free-money">兑换成人民币：<span style="color: #F56C6C;">{{ freeInfo.first_money }} {{ freeInfo.pa_unit }}</span></div>
    </div>
    <div class="free-btn">
      <el-button type="primary" @click="handleRates" size='mini'>确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    freeInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
       currentRow: '',
    };
  },
  methods: {
    // 点击确定按钮
    handleRates(){
      this.$emit('getRatesInfo',this.currentRow)
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex != 0 && columnIndex != 1) { // 假设第三、四、五列的索引分别是2, 3, 4
        // 检查行数据中的消息是否为'该渠道服务不支持'
        if (row.msg === '该渠道服务不支持') {
          console.log(111)
          // 返回合并的行数，例如，合并3行
          return {
            rowspan: 1,
            colspan: 8
          };
        }else{
          return {
            rowspan: 1,
            colspan: 1
          };
        }
      }
      // 默认情况，不合并
    }
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #f5f7fa;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
.hidden-label-radio /deep/.el-radio__label {
  display: none;
}
.free-bot{
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  .free-title{
    font-size: 14px;
    color: #999;
  }
  .free-money{
    font-size: 14px;
    color: #999;
  }
}
.free-btn{
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 0 0;
}
</style>
