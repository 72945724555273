<template>
  <div class="place">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="place-title">收发货信息</div>
        </el-col>
        <el-col :span="12" style="text-align:left">
          <el-button type="primary" icon="el-icon-location-information" @click="selectAddress(1)" size="mini" class="add-select-btn">选择收件人</el-button>
        </el-col>
        <el-col :span="12" style="text-align:left">
          <el-button type="primary" icon="el-icon-location-information" @click="selectAddress(2)" size="mini" class="add-select-btn">选择收件人</el-button>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件人姓名" prop="sender">
            <el-input v-model="ruleForm.sender" placeholder="请输入收件人姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件人姓名" prop="collector">
            <el-input v-model="ruleForm.collector" placeholder="请输入收件人姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件人电话" prop="sendPhone">
            <el-input v-model="ruleForm.sendPhone" placeholder="请输入收件人电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件人电话" prop="collectPhone">
            <el-input v-model="ruleForm.collectPhone" placeholder="请输入收件人电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件人省市县" prop="jjr_sheng">
            <el-cascader
              placeholder="请选择收件人省市县" 
              v-model="region1"
              :options="dataCity"
              :props="{ expandTrigger: 'hover', value: 'label'}"
              style="width: 100%"
              @change="handleChange1"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件人省市县" prop="sjr_sheng">
            <el-cascader
              v-model="region2"
              placeholder="请选择收件人省市县" 
              :options="dataCity"
              :props="{ expandTrigger: 'hover', value: 'label'}"
              style="width: 100%"
              @change="handleChange2"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件人详细地址" prop="sendAddress">
            <el-input v-model="ruleForm.sendAddress" placeholder="请输入收件人详细地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收件人详细地址" prop="collectAddress">
            <el-input v-model="ruleForm.collectAddress" placeholder="请输入收件人详细地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="place-title">货物信息</div>
        </el-col>
        <el-col :span="12">
          <el-form-item label="货物名称" prop="goodsName">
            <el-input v-model="ruleForm.goodsName" placeholder="请输入货物名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="重量(kg)" prop="goodsWeight">
            <el-input v-model="ruleForm.goodsWeight" placeholder="请输入重量"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="体积(m³)" prop="goodsVolume">
            <el-input v-model="ruleForm.goodsVolume" placeholder="请输入体积"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="place-title">服务信息</div>
        </el-col>
        <el-col :span="12">
          <el-form-item label="付款方式" prop="pay_label">
            <el-select v-model="ruleForm.pay_label" placeholder="请选择付款方式" style="width: 100%">
              <el-option label="到付" value="到付"></el-option>
              <el-option label="现付" value="现付"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注信息" prop="remarks">
            <el-input v-model="ruleForm.remarks" placeholder="请输入备注信息"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')" style="width: 160px">提交</el-button>
            <el-button @click="resetForm('ruleForm')" style="width: 160px">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 表单弹窗 -->
    <el-dialog
      title="选择地址"
      append-to-body
      :visible.sync="formVisible"
      width="1000px"
      :before-close="closeDialog"
    >
      <AddressList
        ref="formModal"
        :isType="isType"
        v-if="formVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { orderCreate,defaultAddress } from "@/api"
const  dataCity = require('@/common/city.json')
import AddressList from "../address/list";
export default {
  components: {
    AddressList,
  },
  data() {
    return {
      formVisible: false,
      isType: 1, // 地址类型
      ruleForm: {
        sender: "", //收件人姓名
        sendPhone: "", //收件人联系电话
        jjr_sheng: "", //收件地址-省
        jjr_shi: "", //收件地址-市
        jjr_qu: "", //收件地址-区
        sendAddress: "", //收件地址-详细地址
        collector: "", //收件人姓名
        collectPhone: "", //收件人联系电话
        sjr_sheng: "", //收件地址-省
        sjr_shi: "", //收件地址-市
        sjr_qu: "", //收件地址-区
        collectAddress: "", //收件地址-详细地址
        goodsName: "", //货物名称
        goodsWeight: "", //重量(kg)
        goodsVolume: "", //体积(m 3 )
        remarks: "", //备注
        pay_label: "到付", //付款方式（两种：到付和现付 传字符串就行）
        goodsNumber: 1, //商品数量（默认写1就行）
      },
      dataCity,
      region1:[],
      region2:[],
      rules: {
        sender: [{ required: true, message: "请输入收件人姓名", trigger: "blur" }],
        collector: [{ required: true, message: "请输入收件人姓名", trigger: "blur" }],
        sendPhone: [
          { required: true, message: '请输入收件人电话', trigger: 'blur' },
          // { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
        ],        
        collectPhone: [
          { required: true, message: '请输入收件人电话', trigger: 'blur' },
          // { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
        ],
        jjr_sheng: [{ required: true, message: "请选择收件人省市县", trigger: "change" }],
        sjr_sheng: [{ required: true, message: "请选择收件人省市县", trigger: "change" }],
        sendAddress: [{ required: true, message: "请输入收件人详细地址", trigger: "blur" }],
        collectAddress: [{ required: true, message: "请输入收件人详细地址", trigger: "blur" }],
        goodsName: [{ required: true, message: "请输入货物名称", trigger: "blur" }],
        goodsWeight: [{ required: true, message: "请输入重量", trigger: "blur" }],
        goodsVolume: [{ required: true, message: "请输入体积", trigger: "blur" }],
      },
    };
  },
  created(){
    this.getDefaultAddress()
  },
  methods: {
    // 获取默认地址
    getDefaultAddress(){
      defaultAddress({
        user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
      }).then(res => {
        if(res.code == 1){
          let j = res.data.j_address // 收件
          let s = res.data.s_address // 收件
          if(j){
            this.region1 = [j.province,j.city,j.area]
            this.ruleForm.sender = j.realname, //收件人姓名
            this.ruleForm.sendPhone = j.mobile, //收件人联系电话
            this.ruleForm.jjr_sheng = j.province, //收件地址-省
            this.ruleForm.jjr_shi = j.city, //收件地址-市
            this.ruleForm.jjr_qu = j.area, //收件地址-区
            this.ruleForm.sendAddress = j.address //收件地址-详细地址
          }
          if(s){
            this.region2 = [s.province,s.city,s.area]
            this.ruleForm.collector = s.realname, //收件人姓名
            this.ruleForm.collectPhone = s.mobile, //收件人联系电话
            this.ruleForm.sjr_sheng = s.province, //收件地址-省
            this.ruleForm.sjr_shi = s.city, //收件地址-市
            this.ruleForm.sjr_qu = s.area, //收件地址-区
            this.ruleForm.collectAddress = s.address //收件地址-详细地址
          }
        }
      })
    },
    // 收件省市县
    handleChange1(value) {
      this.ruleForm.jjr_sheng = value[0]
      this.ruleForm.jjr_shi = value[1]
      this.ruleForm.jjr_qu = value[2]
    },
    // 收件省市县
    handleChange2(value) {
      this.ruleForm.sjr_sheng = value[0]
      this.ruleForm.sjr_shi = value[1]
      this.ruleForm.sjr_qu = value[2]
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = {
            ...this.ruleForm,
            user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
          }
          orderCreate(form).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.$router.push({ path: '/order/list'})
            }else{
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 地址弹窗
    selectAddress(num){
      this.isType = num
      this.formVisible = true
    },
    // 关闭弹框
    closeDialog() {
      this.isType = 1;
      this.formVisible = false;
    },
    //新增、修改成功的回调
    editSuccess(record,isType) {
      this.formVisible = false;
      if(isType==1){
        this.region1 = [record.province,record.city,record.area]
        this.ruleForm.sender = record.realname, //收件人姓名
        this.ruleForm.sendPhone = record.mobile, //收件人联系电话
        this.ruleForm.jjr_sheng = record.province, //收件地址-省
        this.ruleForm.jjr_shi = record.city, //收件地址-市
        this.ruleForm.jjr_qu = record.area, //收件地址-区
        this.ruleForm.sendAddress = record.address //收件地址-详细地址
      }
      if(isType==2){
        this.region2 = [record.province,record.city,record.area]
        this.ruleForm.collector = record.realname, //收件人姓名
        this.ruleForm.collectPhone = record.mobile, //收件人联系电话
        this.ruleForm.sjr_sheng = record.province, //收件地址-省
        this.ruleForm.sjr_shi = record.city, //收件地址-市
        this.ruleForm.sjr_qu = record.area, //收件地址-区
        this.ruleForm.collectAddress = record.address //收件地址-详细地址
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .place{
    background: #fff;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    .place-title{
      width: 100%;
      font-size: 16px;
      line-height: 32px;
      text-align: left;
      color: #409EFF;
    }
    .demo-ruleForm{
      // width: 50%;
    }
  }
  /deep/.el-form-item__content{
    text-align: left;
  }
  .add-select-btn{
    margin: 10px 30px;
  }
</style>
