import { get, post } from '@/utils/request';

// 请求接口
export const usersSendCode = (data) => post('users/send_code',data); // 发送登录或注册验证码
export const usersLogin = (data) => post('users/login',data); // 登录或注册
export const usersInfo = (param) => get('users/user_data',param); // 用户信息
export const usersUpdate = (data) => post('users/update',data); // 修改用户信息
export const orderCreate = (data) => post('order/create',data); // 在线下单
export const orderIndex = (param) => get('order/index',param); // 订单列表
export const addressAdd = (data) => post('users/address_add',data); // 添加收发件地址
export const address = (param) => get('users/address',param); // 收发件地址列表
export const defaultAddress = (param) => get('users/default_address',param); // 默认的收发件地址
export const defaultUpdate = (param) => get('users/default_update',param); // 修改收发件默认地址
export const addressUpdate = (data) => post('users/address_update',data); // 修改收发件地址
export const addressDel = (param) => get('users/address_del',param); // 删除收发件地址