/**
 * Created by Administrator.
 */
import axios from 'axios';
import router from '@/router/index';
// 设置请求超时的时间
axios.defaults.timeout = 300000; //300000
// 设置访问的域名前缀
axios.defaults.baseURL = 'https://ppsdex.com/wapi/';

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    config.data = config.data;
    // 设置请求头
    // config.headers = {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   // "content-type": "application/json;charset=UTF-8"
    // };
    // if(token){
    //   config.params = {'token':token}
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      router.push({
        path: '/login',
        querry: { redirect: router.currentRoute.fullPath }, // 从哪个页面跳转
      });
    }
    return response;
  },
  (error) => Promise.reject(error.response.data),
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
// json格式的数据封装
export function postj(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
      headers: {
        "content-type": "application/json;charset=UTF-8"
      }
    })
      .then((response) => {
        resolve(response.data);
      }, (err) => {
        reject(err);
      });
  });
}

// 键值对数据格式封装
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    // 使用URLSearchParams来序列化数据
    let postData = new URLSearchParams();
    for (let key in data) {
      postData.append(key, data[key]);
    }
    // 发送请求，设置Content-Type为application/x-www-form-urlencoded
    axios.post(url, postData, {
      headers: {
          "Content-Type": "application/x-www-form-urlencoded"
      }
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}