<template>
  <div class="" style="padding-bottom: 10px">
    <p class="item" v-for="(item,index) in sheetList" :key="index">{{ item }}</p>
  </div>
</template>

<script>
export default {
  props: {
    sheetList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
  .item{
    font-size: 14px;
    line-height: 16px;
    color: #F56C6C;
  }
</style>