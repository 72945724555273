import Vue from 'vue';
import VueRouter from 'vue-router';
// import Index from '@/views/official/index'; // 官网首页-框架

import Layout from '@/views/layout/index'; // 后台首页
import Place from '@/views/order/place'; // 国内订单
import FPlace from '@/views/order/fplace'; // 国际订单
import OrderList from '@/views/order/list'; // 订单列表
import Address from '@/views/address/list'; // 地址列表
import FinanceConsumption from '@/views/finance/consumption'; // 消费流水
import FinanceCredit from '@/views/finance/credit'; // 信用流水
import FinanceInvoice from '@/views/finance/invoice'; // 发票列表
import Facilitator from '@/views/facilitator/list'; // 服务商列表
import Member from '@/views/user/member'; // 会员中心
import Info from '@/views/user/info'; // 个人信息
import Password from '@/views/user/password'; // 修改密码
import Login from '@/views/login/index'; // 登录
import Register from '@/views/register/index'; // 注册

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: Login,
    children:[
      {
        path: '/',
        name: 'Login',
        component: Login,
        meta:{
          title: 'CTS打单系统'
        }
      },
    ]
  },
  {
    path: '/layout',
    component: Layout,
    redirect: '/order/place',
    children:[
      {
        path: '/order/place',
        name: 'Place',
        component: Place,
        meta:{
          title: '国内订单'
        }
      },    
      {
        path: '/order/fplace',
        name: 'FPlace',
        component: FPlace,
        meta:{
          title: '国际订单'
        }
      },   
      {
        path: '/order/list',
        name: 'OrderList',
        component: OrderList,
        meta:{
          title: '全部订单'
        }
      },  
      {
        path: '/member',
        name: 'Member',
        component: Member,
        meta:{
          title: '会员中心'
        }
      }, 
      {
        path: '/info',
        name: 'Info',
        component: Info,
        meta:{
          title: '个人信息'
        }
      }, 
      {
        path: '/password',
        name: 'Password',
        component: Password,
        meta:{
          title: '修改密码'
        }
      }, 
      {
        path: '/address',
        name: 'Address',
        component: Address,
        meta:{
          title: '地址列表'
        }
      }, 
      {
        path: '/finance/consumption',
        name: 'FinanceConsumption',
        component: FinanceConsumption,
        meta:{
          title: '消费流水'
        }
      }, 
      {
        path: '/finance/credit',
        name: 'FinanceCredit',
        component: FinanceCredit,
        meta:{
          title: '信用流水'
        }
      }, 
      {
        path: '/finance/invoice',
        name: 'FinanceInvoice',
        component: FinanceInvoice,
        meta:{
          title: '发票列表'
        }
      }, 
      {
        path: '/facilitator/list',
        name: 'Facilitator',
        component: Facilitator,
        meta:{
          title: '服务商列表'
        }
      }, 
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta:{
      title: '注册'
    }
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
