<template>
  <div class="info">
  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" size="mini">
    <el-form-item label="旧密码" prop="oldpassword">
      <el-input type="password" v-model="ruleForm.oldpassword" autocomplete="off" placeholder="请输入旧密码"></el-input>
    </el-form-item>
    <el-form-item label="新密码" prop="newpassword">
      <el-input type="password" v-model="ruleForm.newpassword" autocomplete="off" placeholder="请输入新密码"></el-input>
    </el-form-item>
    <el-form-item label="确认密码" prop="checkPass">
      <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" placeholder="请输入确认密码"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </el-form-item>
  </el-form>
  </div>
</template>

<script>
  import { passEdit } from '@/api/user'
  export default {
    data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入新密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.newpassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        ruleForm: {
          oldpassword: '',
          newpassword: '',
          checkPass: '',
        },
        rules: {
          oldpassword: [
            {required: true, message: "请输入旧密码", trigger: 'blur' }
          ],
          newpassword: [
            {required: true, validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            {required: true, validator: validatePass2, trigger: 'blur' }
          ]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          console.log(valid)
          if (valid) {
            let form = {
              ...this.ruleForm,
              user_code:JSON.parse(window.localStorage.getItem("userInfo")).user_code
            }
            
            passEdit(form).then(res => {
              if(res.code == 1){
                this.resetForm(formName)
                this.$message.success(res.msg)
              }else{
                this.$message.error(res.msg)
              }
            })
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  }
</script>
<style lang="scss" scoped>
  .info{
    background: #fff;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    .demo-ruleForm{
      width: 50%;
    }
  }
</style>