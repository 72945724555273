<template>
  <div class="">
    <!-- 消费流水 -->
    <el-card class="box-card">
      <div class="search-form" style="text-align: left">
        <el-form ref="searchForm" :inline="true" size="mini" :model="searchForm" label-width="50px" label-position="left"
          class="demo-form-inline">
          <el-form-item label="时间">
            <el-date-picker
              style="width:320px;"
              v-model="searchForm.createtime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="	类型">
            <el-select v-model="searchForm.type" placeholder="请选择类型">
              <el-option label="管理员充值" :value="0"></el-option>
              <el-option label="充值" :value="1"></el-option>
              <el-option label="消费" :value="2"></el-option>
              <el-option label="补扣运费" :value="3"></el-option>
              <el-option label="退款" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="mini" @click="handleSearch">查询</el-button>
            <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="" style="margin-bottom: 15px;text-align:left">
        <el-button type="primary" icon="el-icon-download" size="mini" @click="handleExport">导出</el-button>
      </div>
      <el-table :data="dataList" border size="mini" height="600">
        <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
        <el-table-column prop="createtime" label="时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="money" label="金额" show-overflow-tooltip></el-table-column>
        <el-table-column prop="after" label="当前余额" show-overflow-tooltip></el-table-column>
        <el-table-column prop="type" label="类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="platform_order_sn" label="物流单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="porder_sn" label="补扣订单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="memo" label="备注" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" label="补扣说明" show-overflow-tooltip></el-table-column>
      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { consumptionList, consumptionExport } from '@/api/finance'
import { downloadBase64AsExcel } from "@/common/common.js"
export default {
  data() {
    return {
      searchForm: {},
      current: 1,
      size: 20,
      total: 1,
      dataList: [],
    };
  },
  mounted(){
    this.getConsumptionList()
  },
  methods: {
      // 导出数据
      handleExport(){
        consumptionExport({
          user_code:JSON.parse(window.localStorage.getItem("userInfo")).user_code,
          ...this.searchForm
        }).then(res => {
          downloadBase64AsExcel(res.data.file,res.data.name)
        })
      },
    // 获取列表
    getConsumptionList(){
      let queryForm = {}
      if(this.searchForm.createtime){
        queryForm = {
          ...this.searchForm,
          createtime: this.searchForm.createtime.join("/")
        }
      }else{
        queryForm = {
          ...this.searchForm
        }
      }
      consumptionList({
        user_code:JSON.parse(window.localStorage.getItem("userInfo")).user_code,
        page: this.current,
        limit: this.size,
        ...queryForm
      }).then(res => {
        if(res.code == 1){
          this.dataList = []
          this.dataList = res.data.list
          this.total = res.data.total
        }
      })
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getConsumptionList();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.value1 = []
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.getConsumptionList();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>
