<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="mini"
    >
    <el-row :gutter="10">
      <el-col :span="12">
        <el-form-item label="类型" prop="user_type">
          <el-select v-model="ruleForm.user_type" placeholder="请选择类型" style="width: 100%">
            <el-option label="发件人地址" :value="1"></el-option>
            <el-option label="收件人地址" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="姓名" prop="realname">
          <el-input v-model="ruleForm.realname" placeholder="请输入姓名"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-form-item label="公司" prop="company">
          <el-input v-model="ruleForm.company" placeholder="请输入公司"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="国家" prop="country">
          <el-select v-model="ruleForm.country" placeholder="请选择省国家" style="width: 100%" @change="handleChange">
            <el-option v-for="(item,index) in countryList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-form-item label="州/省" prop="province">
          <el-select v-model="ruleForm.province" placeholder="请选择省州/省" style="width: 100%">
            <el-option v-for="(item,index) in provinceList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="城市" prop="city">
          <el-input v-model="ruleForm.city" placeholder="请输入城市"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-form-item label="邮编" prop="zcode">
          <el-input v-model="ruleForm.zcode" placeholder="请输入邮编" @blur="getCityInfo()"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="地址1" prop="address_one">
          <el-input v-model="ruleForm.address_one" placeholder="请输入地址1"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-form-item label="地址2" prop="address_two">
          <el-input v-model="ruleForm.address_two" placeholder="请输入地址2"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
      <!-- 
      <el-form-item prop="is_default">
        <el-checkbox v-model="ruleForm.is_default" :checked="ruleForm.is_default == 1 ? true : false" :true-label="1" :false-label="0" name="is_default">设置成默认地址</el-checkbox>
      </el-form-item> -->
      <el-form-item style="text-align: right;">
        <el-button size="mini" type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button size="mini" @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addressAdd, addressUpdate } from '@/api/address'
import { City } from '@/api/common'
import { validatenull, transformCountryCity, provinceListValue } from "@/common/common.js"
import { cityInfo } from "@/api/order"
export default {
  props:{
    record: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {
        company: '',
        address_two: '',
        email: ''
      },
      rules: {
        user_type: [{ required: true, message: "请选择类型", trigger: "change" }],
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        address_one: [{ required: true, message: '请输入地址1', trigger: 'blur' }],
        country: [{ required: true, message: "请选择国家", trigger: "change" }],
        province: [{ required: true, message: "请选择州省", trigger: "change" }],
        city: [{ required: true, message: "请输入城市", trigger: "blur" }],
        zcode: [{ required: true, message: "请输入邮编", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }]
      },
      countryList: [],// 国家-城市
      provinceList: [],// 国家-城市
      id:''
    };
  },
  mounted(){
    if(!validatenull(this.record)){
      this.id = this.record.id
      this.provinceList = provinceListValue(this.countryList, this.record.country)
      this.ruleForm = {
        ...this.record,
        province: this.record.province.split("-")[0]
      }
    }
    this.getCity()
  },
  methods: {
    // 获取城市
    getCity(){
      City().then(res => {
        this.countryList = transformCountryCity(res.data)
      })
    },
    //获取省市县
    handleChange(value) {
      this.provinceList = provinceListValue(this.countryList, value)
      delete this.ruleForm.province
    },
    // 通过邮编获取州城市
    getCityInfo(num){
      cityInfo({
        zcode: this.ruleForm.zcode
      }).then(res => {
        if(res.code == 1){
          this.ruleForm.country = 'United States'
          this.ruleForm.province = res.data.province
          this.ruleForm.city = res.data.city
          this.provinceList = provinceListValue(this.countryList, this.ruleForm.country)
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.ruleForm
          if(this.id){
            addressUpdate({
              address_id:this.id,
              user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
              ...form
            }).then(res => {
              if(res.code == 1){
                this.$message.success(res.msg)
                this.$emit('ok')
              }else{
                this.$message.error(res.msg)
              }
            }).catch(err => {
              this.$message.error(err.msg)
            })
          }else{
            addressAdd({
              user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
              ...form
            }).then(res => {
              if(res.code == 1){
                this.$message.success(res.msg)
                this.$emit('ok')
              }else{
                this.$message.error(res.msg)
              }
            }).catch(err => {
              this.$message.error(err.msg)
            })
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm = {
        company: '',
        address_two: '',
        email: ''
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .code{
    font-size: 14px;
    cursor: pointer;
    color: #409EFF;
    margin-left: 10px;
    flex: 1;
    text-align: right;
    width: 100px;
    float: right;
  }
</style>
