<template>
  <div class="check">
    <p>经过校验，类型：{{ checkInfo.AddressClassification.Code == 1 ? '商业地址' : '住宅地址' }}, 建议地址：</p>
    <p>{{ checkInfo.Candidate[0].AddressLine }}</p>
    <p>{{ checkInfo.Candidate[0].PoliticalDivision2 }} {{ checkInfo.Candidate[0].PoliticalDivision1 }} {{ checkInfo.Candidate[0].PostcodePrimaryLow }} - {{ checkInfo.Candidate[0].PostcodeExtendedLow }}</p>
    <p>{{ checkInfo.Candidate[0].CountryCode }}</p>
    <div class="check-btn">
      <el-button type="primary" @click="handleSure" size='mini'>确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    handleSure(){
      this.$emit('closeCheck')
    }
  }
}
</script>
<style lang="scss" scoped>
  .check{
    font-size: 14px;
    p{
      font-size: 14px;
      line-height: 16px;
    }
  }
  .check-btn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }
</style>