/**
 * 判断是否为空
 */
export function validatenull(val) {
  if (typeof val == 'boolean') {
      return false;
  }
  if (typeof val == 'number') {
      return false;
  }
  if (val instanceof Array) {
      if (val.length == 0) return true;
  } else if (val instanceof Object) {
      if (JSON.stringify(val) === '{}') return true;
  } else {
      if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true;
      return false;
  }
  return false;
}

// 国家-城市数据格式转换
export const transformCountryCity = (list) => {
  // 定义转换后的数组
  let newCClist = [];
  // 遍历原始数据
  for (const item of list) {
    // 对于每个国家，创建一个新的选项对象
    const countryOption = {
      value: item.name,
      label: item.name,
      children: []
    };
    // 遍历省份列表，并为每个省份创建子选项
    for (const province of item.province_list) {
      countryOption.children.push({
        value: province,
        label: province
      });
    }
    // 将国家选项添加到转换后的数组
    newCClist.push(countryOption);
  }
  return newCClist;
}

// 获取州省列表
export const provinceListValue = (list,value) => {
  let prlist = list.find(item => {return item.label == value}) ? list.find(item => {return item.label == value}).children : []
  return prlist
}


// 下载-base64转excel
export const downloadBase64AsExcel = (base64String, fileName) =>  {
  let base64 = base64String.split("base64,")[1] // 去除之前的数据
  // 解码Base64字符串
  const byteCharacters = atob(base64);
  // 创建一个8位的字符数组
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  // 将整数转换为typed array
  const byteArray = new Uint8Array(byteNumbers);
  // 创建一个blob对象
  const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // 创建URL对象
  const url = URL.createObjectURL(blob);

  // 创建下载链接
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName || 'download.xlsx'; // 设置下载文件的默认文件名

  // 模拟点击
  document.body.appendChild(link);
  link.click();

  // 清理
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

// 下载本地文件
export const createDownloadLink = (href, fileName) => {
  let link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}