<template>
  <div class="">
    <el-table :data="dataList" border size="mini" :height="400">
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column prop="porder_sn" label="物流单号"></el-table-column>
      <el-table-column prop="pa_unit" label="尺寸" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ `${scope.row.pa_length} ${scope.row.pa_unit == 1 ? 'in' : 'cm'} * ${scope.row.pa_width} ${scope.row.pa_unit == 1 ? 'in' : 'cm'} * ${scope.row.pa_height} ${scope.row.pa_unit == 1 ? 'in' : 'cm'}` }}
        </template>
      </el-table-column>
      <el-table-column prop="pa_lb" label="重量" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ `${scope.row.pa_lb} ${scope.row.pa_unit == 1 ? 'lb' : 'kg'}` }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { packageInfo } from '@/api/order'
  export default {
    props: {
      order_id: {
        type: [Number,String],
        default: ''
      }
    },
    data() {
      return {
        dataList: [],
      };
    },
    mounted(){
      this.getPackageInfo()
    },
    methods: {
      // 获取列表
      getPackageInfo(){
        packageInfo({
          user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
          order_id: this.order_id
        }).then(res => {
          if(res.code == 1){
            this.dataList = []
            this.dataList = res.data
          }
        })
      },
    },
  };
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>
