<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="mini"
    >
      <el-form-item label="新手机号" prop="phone">
        <el-input v-model="ruleForm.phone" autocomplete="off" clearable placeholder="请输入手机号">
          <span slot="prefix">+86</span>
        </el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="smsCode">
        <el-input v-model="ruleForm.smsCode" autocomplete="off" clearable placeholder="请输入验证码">
          <template slot="append">
            <div v-if='codeCd'>获取验证码({{ long }})</div>
            <div v-else @click="sendPhoneCode('ruleForm')">获取验证码</div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item style="text-align: right;">
        <el-button size="mini" type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button size="mini" @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addressAdd, addressUpdate,usersSendCode } from '@/api'
export default {
  data() {
    return {
      ruleForm: {
        phone: "",
        smsCode: "",
      },
      long: 60,
      codeCd: false,
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^\d{4}$/, message: '验证码格式不正确', trigger: 'blur' },
        ],
      },
      id:''
    };
  },
  methods: {
    // 发送验证码
    sendPhoneCode(formName){
      this.$refs[formName].validateField('phone', async (valid) => {
        if (!valid) {
          // 获取验证码
          // captcha(this.form.phone)
          this.getUsersSendCode()
          // 开启计时
          this.codeCd = true
          const timer = setInterval(() => {
            this.long--
            if (this.long <= 0) {
              this.long = 60
              this.codeCd = false
              clearInterval(timer)
            }
          }, 1000)
        } else {
          return false
        }
      })
    },
    // 获取验证码
    getUsersSendCode(){
      usersSendCode({phone:this.ruleForm.phone}).then(res => {
        if(res.code == 1){
          // this.phoneCode = res.data
          this.$message.success(res.msg)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.ruleForm
          if(this.id){
            addressUpdate({
              address_id:this.id,
              user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
              ...form
            }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.$emit('ok')
            }else{
              this.$message.error(res.msg)
            }
          })
          }else{
            addressAdd({
              user_code: JSON.parse(window.localStorage.getItem("userInfo")).user_code,
              ...form
            }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.$emit('ok')
            }else{
              this.$message.error(res.msg)
            }
          })
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.value = []
    },
  },
};
</script>
<style lang="scss" scoped>
  .code{
    font-size: 14px;
    cursor: pointer;
    color: #409EFF;
    margin-left: 10px;
    flex: 1;
    text-align: right;
    width: 100px;
    float: right;
  }
</style>
